export const LOCAL_PORT = '3000'; // eslint-disable-line
export const RaasEligibilityEnum = {
  NOT_ELIGIBLE: 'NOT_ELIGIBLE',
  ACCOUNT_NOT_FOUND: 'ACCOUNT_NOT_FOUND',
};

export const SFCC_URL = {
  uat: 'https://staging-na01-simpleenergy.demandware.net/s/',
  development: 'https://bddp-007.dx.commercecloud.salesforce.com/s/',
  production: 'https://production-na01-simpleenergy.demandware.net/s/',
};
export const PAYMENT_METHOD_ID = 'FREE_PRODUCT';
export const DIS_URL = 'https://edge.disstg.commercecloud.salesforce.com/dw/image/v2/BDDP_PRD/';

export const SubmissionStatus = {
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SUBMITTED: 'SUBMITTED',
};

export const EXPRESS_SENTRY_DSN = 'https://e0a6f0730b3e41e1b2f05c7023f3acb6@o10881.ingest.sentry.io/5498186';
export const REACT_SENTRY_DSN = 'https://d054243bd0214dd79df6591a4e5a20f3@o10881.ingest.sentry.io/5498391';

export const errors = {
  PRODUCT_NOT_AVAILABLE: 'Product not available',
  ORDER_FAILURE: 'Order creation failed',
};

export const okapiErrors = {
  ITEM_NOT_AVAILABLE: 'ProductItemNotAvailableException',
  EXPIRED_CUSTOMER_AUTH: 'ExpiredTokenException',
  INVALID_CLIENT_ID: 'InvalidClientIdException',
};

export const EMAIL_VALIDATION_URL = {
  test: 'https://email-validator.stg.rotw.uplight.io',
  development: 'https://email-validator.stg.rotw.uplight.io',
  uat: 'https://email-validator.uat.rotw.uplight.io',
  production: 'https://email-validator.prd.rotw.uplight.io',
};

export const productAddOnOptions = {
  DEFAULT_VALUE: 'NONE',
  OPTION_TYPES: [
    {
      id: 'programEnrollment',
      type: 'program',
      name: 'DRPE',
      values: [],
    },
    {
      id: 'installQuote',
      type: 'service',
      name: 'INSTALL_QUOTE',
      values: [],
    },
  ],
};
