import fetchData from '../../shared/helpers/fetchData';
import fetchOptions from '../../shared/helpers/fetchOptions';
import getValueFromWindow from '../../shared/helpers/getValueFromWindow';
import OrderProcessActionType from './types';
import { EMAIL_VALIDATION_URL } from '../../../config/constants';


export const resetEligibility = () => ({
  type: OrderProcessActionType.RESET_ELIGIBILITY,
});

export const startSubmittingForm = () => ({
  type: OrderProcessActionType.SUBMITTING_FORM,
});

export const receiveFormSubmissionResponse = response => ({
  response,
  type: OrderProcessActionType.RECEIVE_FORM_SUBMISSION_RESPONSE,
});

export const submitStepFormsRequest = (url, payload) => (dispatch) => {
  const options = fetchOptions('post', { ...payload });
  const handlers = {
    successHandler: receiveFormSubmissionResponse,
    dispatch,
  };

  dispatch(startSubmittingForm());
  return fetchData(url, options, handlers);
};

export const requestEmailValidation = () => ({
  type: OrderProcessActionType.VALIDATE_EMAIL,
});

export const receiveEmailValidationResponse = response => ({
  response,
  type: OrderProcessActionType.RECEIVE_EMAIL_VALIDATION_RESPONSE,
});

export const validateEmail = email => (dispatch) => {
  const options = fetchOptions('post', { email });
  const handlers = {
    successHandler: receiveEmailValidationResponse,
    dispatch,
  };

  dispatch(requestEmailValidation());
  const environment = getValueFromWindow('globals', 'environment');
  const url = EMAIL_VALIDATION_URL[environment];

  return fetchData(`${url}/validate-email`, options, handlers);
};
